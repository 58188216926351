import React from 'react'

const AlertModal = ({setAlert, alert}) => {
    return (
        <>
            <div class="modal" tabindex="-1" style={{ background: "rgba(0,0,0, 0.5)" }} role="dialog" id="myModal" aria-hidden="true" data-backdrop="false">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document" >
                    <div class="modal-content">
                        <div className="modal-header  p-2">
                            <h4 className="modal-title">Alert</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setAlert()} style={{ color: 'red' }}>&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <div className="dropdown__box ">
                                            <span htmlFor="">First Name</span> : {alert?.data?.firstName}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12  mt-4">
                                        <div className="dropdown__box  ">
                                            <span htmlFor="">Last Name</span> :  {alert?.data?.lastName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary">Save</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default AlertModal