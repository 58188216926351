import React, { useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './Input.css';
import { DecryptedList, EncryptedList, encrypt } from '../Commun/Utlity';
import { toastifyError } from '../Commun/AlertMsg';
import axios from 'axios';

export const Input = ({ user_Message_Insert, onlineStatus, groupName, ws, setMessagesData, MessData, username }) => {

    const [textMessage, setTextMessage] = useState('')
    const [imageBaseUrl, setImageBaseUrl] = useState('')
    const [imageName, setImageName] = useState('')
    const [image, setImage] = useState('')

    const sendMessage = (e) => {
        e.preventDefault();
        if (window.location.pathname === '/chat') {
            if (onlineStatus === 'offline') {
                toastifyError('Internet Disconnected')
            } else {
                if (!username) {
                    toastifyError('Empty ToUserName');
                    return;
                }

                if (!textMessage && !imageBaseUrl) {
                    toastifyError('Empty Field');
                    return;
                }
                // else if (textMessage.trim() === '') {
                //     toastifyError('Empty Message');
                //     return;
                // }
                else {
                    //     const updatedMess = [...MessData.current];
                    //   updatedMess.push({type:'send', Mess: textMessage, FromUserName:name, ToUserName:username });
                    //   setMessagesData(updatedMess)
                    const updatedMess = [...MessData.current];
                    updatedMess.push({ ChatMessage: textMessage, FromUser: sessionStorage.getItem('UserName'), ToUsers: username, GroupName: groupName, Image: imageBaseUrl });
                    setMessagesData(updatedMess)
                }
                var reqSend = {
                    Method: "Send",
                    FromUserName: sessionStorage.getItem('UserName'),
                    ToUserNameList: username.split(','),
                    Type: groupName ? '1' : '0',
                    GroupName: groupName,
                    // Content:textMessage
                    Content: JSON.stringify({
                        img: imageBaseUrl,
                        Message: textMessage
                    })
                };
                var plain = JSON.stringify(reqSend);
                var secret = encrypt(plain);
                console.log(reqSend);
                ws.send(secret);
                if (textMessage || imageBaseUrl) {
                    user_Message_Insert(sessionStorage.getItem('UserName'), username, textMessage, new Date(), imageBaseUrl, groupName ? '1' : '0', groupName)
                }
                setTimeout(() => {
                    setTextMessage(''); setImageBaseUrl(''); setImageName('')
                }, [100])
            }
        } else {
            alert('not open')
        }
    }

    const handleFileInputChange = e => {
        // document.getElementById("btn").style.display = "none";
        if (e.target.files.length > 0) {
            setImage(e.target.files[0])
            setImageName(e.target.files[0].name)
            upload_Image_File(e.target.files[0])
            const str = formatBytes(e.target.files[0].size)
            const myArray = str.split(",", 3);
            if (myArray[1] === 'KiB') {
                if (parseInt(myArray[0]) < 51) {
            getBase64(e.target.files[0])
                .then(result => {
                    e.target.files[0]["base64"] = result;
                    // console.log("File Is", e.target.files[0]);
                })
                .catch(err => {
                    console.log(err);
                });
                    } else {
                        toastifyError('Image size is greater than 50KiB')
                    }
                } else {
                    toastifyError('Image size is greater than 50KiB')
                }
        }
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                // console.log(baseURL);
                setImageBaseUrl(baseURL)
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        console.log(`${parseInt((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}}`);
        return `${parseInt((bytes / Math.pow(k, i)).toFixed(dm))},${sizes[i]}`
    }

    const upload_Image_File = async (file) => {
        // e.preventDefault();
        const val = {
            FromUser: sessionStorage.getItem('UserName'),
            ToUsers: username,
            CreatedByUserFK: '',
        }
        const values = EncryptedList(JSON.stringify(val));
        var formdata = new FormData();
        formdata.append("Image", file);
        formdata.append("Data", values);
        const res = await axios.post('https://apigoldline.com:5002/api/ChatMessage/InsertChatRms', formdata);
        if (res.code === 'ERR_BAD_RESPONSE') {
            console.log(res.response.data.Message);
        } else {
            const decr = DecryptedList(res.data.data)
            const TextData = JSON.parse(decr)
            // document.getElementById("btn").style.display = "block";
            setImageBaseUrl(TextData.Table[0].Image)
        }
    }

    return (
        <>
            <form action="">
                <p style={{ paddingLeft: '10px' }}>{imageName}</p>
                <div className="row">
                    <div className="col-4 col-md-2 col-lg-1  pt-2 pl-4" style={{ overflow: 'hidden' }}>
                        <span class="btn-file">
                            <i className="fa fa-paperclip" />
                            <input type="file" name="file" accept="image/jpeg, image/jpg" onChange={handleFileInputChange}
                            onLoad={(event) => {
                                console.log( event.target.naturalHeight);
                                // setSize({
                                //     height: event.target.naturalHeight,
                                //     width: event.target.naturalWidth
                                // });
                            }}
                            />
                        </span>
                    </div>
                    <div className="col-6 col-md-8 col-lg-9 p-0">
                        <input
                            className="input"
                            type="text"
                            value={textMessage}
                            onChange={(e) => {
                                setTextMessage(e.target.value)
                            }
                            }
                            placeholder="Type a message..."
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 p-0" id='btn' style={{ display: 'block' }}>
                        <button className="sendButton" onClick={sendMessage}><div><i className="fa fa-paper-plane" /></div></button>
                    </div>
                </div>
            </form>
        </>
    )
}



