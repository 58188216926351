import moment from "moment/moment";

var CryptoJS = require("crypto-js");
// import CryptoJS from 'crypto-js';
export function encrypt(plain) {
     var aesKey = '0ca175b9c0f726a831d895e269332461';
    var key = CryptoJS.enc.Utf8.parse(aesKey);
    var encryptedData = CryptoJS.AES.encrypt(plain, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
  }

  export function decrypt(secret) {
    var aesKey = '0ca175b9c0f726a831d895e269332461';
    var key = CryptoJS.enc.Utf8.parse(aesKey);
    var decryptedData = CryptoJS.AES.decrypt(secret, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decryptedData.toString(CryptoJS.enc.Utf8);
  }

  // Encrypted And Decrypted -> Key 
var Key = '9z$C&F)J@NcRfTjW'
var Code = 'QUJDREVGR0g='

export const DecryptedList = (response) => {
  var key = CryptoJS.enc.Utf8.parse(Key);
  var iv = CryptoJS.enc.Base64.parse(Code);
  var bytes = CryptoJS.TripleDES.decrypt(response, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return bytes.toString(CryptoJS.enc.Utf8);
}
// Encrypt Data 
export const EncryptedList = (text) => {
  var key = CryptoJS.enc.Utf8.parse(Key);
  var iv = CryptoJS.enc.Base64.parse(Code);
  var encoded = CryptoJS.enc.Utf8.parse(text);
  var bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  return bytes.toString();
}


// Date Time Format
export const getShowingMonthDateYear = (dateStr) => {
  return moment(dateStr).format("MM/DD/YYYY HH:mm:ss")
}







  