import React from 'react'
import { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toastifyError, toastifySuccess } from '../Commun/AlertMsg'
import { fetchPostData } from '../hooks/Api'

const NewPassword = () => {

const [value, setValue] = useState({
    'UserName':'','UserPassword':'','CmPassword':'',
})
const navigate = useNavigate()

const handleChange = (e) => {
    // console.log(e)
    setValue({
        ...value,
        [e.target.name]:e.target.value
    })
}

const Submit = () => {
    fetchPostData('https://rmsapi2.arustu.com/api/Chat/ForgotChatPassword',value).then((data)=>{
        if(data){
            if(data[0].Message  === 'Update Successfully'){
                toastifySuccess(data[0].Message);
                navigate('/')
            } else{
                toastifyError(data[0].Message)
            }
        }
    })
}

  return (
   <>
       <section className="vh-100" style={{ backgroundColor: '#ddd' }}>
                <div className="container-fluid py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-5 col-xl-4">
                            <div className="card shadow-2-strong" style={{ borderRadius: '1rem', }}>
                                <div className="card-body p-5 ">
                                    <h3 className="text-center" >Change Password</h3>
                                    <hr />
                                    <div className="form-group">
                                        <label className="form-label">Username</label>
                                        <input placeholder="UserName" onChange={handleChange} name='UserName' className="form-control " type="text" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">New Password</label>
                                        <input placeholder="UserName"  onChange={handleChange} name='UserPassword' className="form-control " type="text" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Confirm Password</label>
                                        <input placeholder="UserName"  onChange={handleChange} name='CmPassword' className="form-control " type="text" />
                                    </div>
                                    <div className='mt-3 text-center col-12'>
                                        <button className='btn btn-md btn-login' onClick={Submit} type="submit">Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
   </>
  )
}

export default NewPassword