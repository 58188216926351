import './App.css';
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Join from './components/Join/Join'
import Chat from './components/Chat/Chat';
import { decrypt, encrypt, EncryptedList, getShowingMonthDateYear } from './components/Commun/Utlity'
import SignUp from './components/SignUp/SignUp';
import { ToastContainer } from 'react-toastify';
import { fetchPostData } from './components/hooks/Api';
import Group from './components/Group/Group';
import NewPassword from './components/Join/NewPassword';
import axios from 'axios';

function App() {

  const [name, setName] = useState()
  const [username, setUserName] = useState('')
  const [groupName, setGroupName] = useState('')
  const [messages, updateMessages] = useState([]);
  const [MessagesData, setMessagesData] = React.useState([])
  const [OnlineUsername, setOnlineUsername] = useState([])
  const [showMess, setShowMess] = useState()
  const [onlineStatus, setOnlineStatus] = useState('')
  const [alert, setAlert] = useState()

  const [messageStatus, setMessageStatus] = useState([])
  const latestChat = React.useRef(null);
  latestChat.current = messages;

  const MessData = React.useRef(null)
  MessData.current = MessagesData

  const onlineUser = React.useRef(null);
  onlineUser.current = OnlineUsername;

  var globalIndex = 0;

  const [isPaused, setPause] = useState(false);
  const [ws, setWs] = useState(null);
  const [moonfox, setMoonfox] = useState(null)


  // Online status check 
  window.addEventListener("online", function () {
    setOnlineStatus("online")
  })

  window.addEventListener("offline", function () {
    setOnlineStatus("offline")
  })

  useEffect(() => {
    connect_Web()
  }, []);

  const connect_Web = () => {

    const wsClient = new WebSocket('wss://www.rmswebsocket.com:54183');
    const moonClient = new WebSocket('wss://www.rmswebsocket.com:54183');


    wsClient.onopen = () => {
      console.log('ws opened');
      setWs(wsClient);
      setMoonfox(moonClient)
      // if(sessionStorage.getItem('UserName')){

      //   logout(wsClient)
      // }
      if (sessionStorage.getItem('UserName')) {
        Login_User(wsClient)
      }
    };
    wsClient.onclose = () => { console.log('ws closed'); setTimeout(connect_Web, 3000); }

    return () => {
      wsClient.close();
    }
  }

  useEffect(() => {
    if (!ws) return;
    if (!moonfox) return;
    ws.onmessage = e => {
      if (isPaused) return;
      var plain = decrypt(e.data);
      var json = JSON.parse(plain);
      if (json.Method === "Login") {
        user_Status('true', json.FromUserName)
      }
      // console.log(json);
      if (json.Method === "Logout") {
        user_Status('false', json.FromUserName); setUserName('')
        window.location.reload();
      }
      if (json.Method === "Receive") {
        // console.log(json);
        if (json.Messages[0].FromUserName === sessionStorage.getItem('name') && json.GroupName) {
          Update_Message(json.Messages[0].FromUserName, json.Messages[0].ToUserName, true, getShowingMonthDateYear(new Date()), null)
        } else if (json.GroupName === sessionStorage.getItem('group') && json.FromUserName != sessionStorage.getItem('UserName')) {
          Update_Message(json.Messages[0].FromUserName, json.Messages[0].ToUserName, true, getShowingMonthDateYear(new Date()), json.GroupName)
        } else if (json.Messages[0].FromUserName === sessionStorage.getItem('name') && json.GroupName === "") {
          Update_Message(json.Messages[0].FromUserName, json.Messages[0].ToUserName, true, getShowingMonthDateYear(new Date()), null)
        }
        else {
          Update_Message(json.Messages[0].FromUserName, json.Messages[0].ToUserName, false, null, null)
        }
      }

      if (json.Method === "Send") {
        setTimeout(() => {
          user_Message_Status()
        }, 2000)

      }

      if (json.Users) {
        if (json.Users.length === 1) {
          if (json.Users[0].Online) {
            // alert('dd')
            user_Status('true', json.FromUserName)
          } else {
            // alert('false')
            user_Status('false', json.FromUserName);
            // setUserName('')
          }
        }
      }
      if (json.Messages) {

        const Content = JSON.parse(json.Messages[0].Content.replace(/&quot;/ig, '"'))
        // user_Message_Insert(json.Messages[0].FromUserName, json.Messages[0].ToUserName, json.Messages[0].Content, json.Messages[0].SendTime)
        if (json.GroupName != 'Ref') {
          setShowMess(json)
          const updatedChat = [...latestChat.current];
          updatedChat.push({ name: json.Messages[0].FromUserName, messages: Content.Message, GroupName: json.GroupName });
          updateMessages(updatedChat);

          // const updatedMess = [...MessData.current];
          // updatedMess.push({ type: 'receive', Mess: json.Messages[0].Content, FromUserName: json.Messages[0].FromUserName, ToUserName: json.Messages[0].ToUserName });
          // setMessagesData(updatedMess)
          if (json.Messages[0].FromUserName != sessionStorage.getItem('UserName')) {
            const updatedMess = [...MessData.current];
            updatedMess.push({ ChatMessage: Content.Message, FromUser: json.Messages[0].FromUserName, ToUsers: json.Messages[0].ToUserName, GroupName: json.GroupName, Image: Content.img });
            setMessagesData(updatedMess)
          }

          if (json.Messages[0].ContentType === "MoonfoxDataApi") {
            setAlert(Content)
          }
        } else {
          user_Message_Status()
        }

        //  console.log(Content.img);
      }
    };
  }, [isPaused, ws]);

  const [userList, setUserList] = useState([])
  const [filterUserList, setFilterUserList] = useState([])

  // useEffect(() => {
  //   get_User_List()
  // }, [])

  const get_User_List = () => {
    const val = {
      DisplayName: sessionStorage.getItem('UserName')
    }
    fetchPostData('https://apigoldline.com:5002/api/Chat/GetData_Chat', val)
      .then((res) => {
        if (res) {
          setUserList(res); setFilterUserList(res)
        }
      })
  }

  const user_Status = (status, UserName) => {
    const val = {
      IsOnline: status,
      UserName: UserName
    }
    fetchPostData('https://apigoldline.com:5002/api/Chat/UpdateOnlineOffline', val)
      .then((res) => {
        if (res) {
          if (res[0].Message === 'Update Successfully') {
            get_User_List()
          }
        }
      })
  }

  const logout = (wsClient) => {
    var reqLogout = {
      Method: "Logout",
      FromUserName: sessionStorage.getItem('UserName')
    };
    var plain = JSON.stringify(reqLogout);
    var secret = encrypt(plain);
    wsClient.send(secret);

  }

  const user_Message_Insert = (FromUser, ToUsers, ChatMessage, SendDateTime, img, IsGroupMsg, GroupName) => {
    const val = {
      'FromUser': FromUser, 'ToUsers': ToUsers, 'IsGroupMsg': IsGroupMsg, 'ChatMessage': ChatMessage, 'GroupName': GroupName, 'IsRead': '', 'ReadDateTime': '', 'SendDateTime': getShowingMonthDateYear(SendDateTime), 'FromUserId': '', 'ToUserID': '', 'Image': img
    }
    fetchPostData('https://apigoldline.com:5002/api/ChatMessage/InsertChatRms', val)
  }

  const Login_User = async (wsClient) => {
    const value = {
      'UserName': sessionStorage.getItem('UserName'), UserPassword: sessionStorage.getItem('UserPassword')
    }

    const val = EncryptedList(JSON.stringify(value));
    const data = {
      "Data": val
    }
    try {
      const res = await axios.post('https://apigoldline.com:5002/api/Chat/LoginChatUser', data);
      if (res.data.success) {
        login(wsClient);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const login = (wsClient) => {
    var reqLogin = {
      Method: "Login",
      FromUserName: sessionStorage.getItem('UserName'),
      GlobalIndex: globalIndex
    };
    var plain = JSON.stringify(reqLogin);
    var secret = encrypt(plain);
    wsClient.send(secret);
    // navigate('/chat')
  };

  const user_Message_Status = () => {
    const val = {
      'FromUser': sessionStorage.getItem('UserName'), 'ToUsers': sessionStorage.getItem('name'), 'FromUser1': sessionStorage.getItem('name'), 'ToUsers1': sessionStorage.getItem('UserName'),
    }
    fetchPostData('https://apigoldline.com:5002/api/ChatMessage/GetData_ChatUsersRms', val)
      .then(res => {
        if (res) setMessageStatus(res)
        else setMessageStatus()
      })
  }

  const Update_Message = (FromUser, ToUsers, IsRead, Date, GroupName) => {
    const val = {
      'IsRead': IsRead, 'ReadDateTime': Date, 'FromUser': FromUser, 'ToUsers': ToUsers, 'IsDeliverMessage': true, 'GroupName': GroupName
    }
    fetchPostData('https://apigoldline.com:5002/api/ChatMessage/UpdateRms', val)
  }

  return (
    <BrowserRouter >
      <div id="main_content">
        <Routes>
          <Route exact path="/" element={<Join {...{ globalIndex, ws, setName }} />} />
          <Route exact path="/chat" element={<Chat {...{ moonfox, user_Message_Status, messageStatus, setAlert, alert, Update_Message, onlineStatus, user_Message_Insert, get_User_List, user_Status, filterUserList, setFilterUserList, userList, setShowMess, showMess, messages, name, setName, globalIndex, ws, OnlineUsername, setMessagesData, MessData, MessagesData, username, setUserName, groupName, setGroupName }} />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/group" element={<Group />} />
          <Route exact path="/forgot-password" element={<NewPassword />} />
          {/* <Route exact path="/dashboard" element={<Dashboard   />} /> */}
        </Routes>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;



// if (json.Users) {
//   if (json.Users.length == 1) {
//     if (json.Users[0].Online === true) {
//       console.log('json1', json);
//       // get_User_List()
//       user_Status('true')
//       const updatedOnlineUser = [...onlineUser.current];
//       updatedOnlineUser.push({ Online: json.Users[0].Online, UserName: json.Users[0].UserName });
//       setOnlineUsername(updatedOnlineUser)
//     } else {
//       // user_Status('false')
//       const val = [...onlineUser.current]?.filter(function (element) {
//         return element.UserName !== json.Users[0].UserName;
//       });
//       setOnlineUsername(val)
//     }
//   } else {
//     console.log('json3', json);
//     setOnlineUsername(json.Users)
//   }
// }
