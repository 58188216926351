import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toastifyError, toastifySuccess } from '../Commun/AlertMsg';
import { EncryptedList } from '../Commun/Utlity';
import { fetchPostData } from '../hooks/Api';

const SignUp = () => {

    const [value, setValue] = useState({
        'UserName': '',
        'UserPassword': '',
        'IsOnline': '',
        'DisplayName': ''
    })

    const navigate = useNavigate()
    const [colour1, setColour1] = useState('red')
    const [colour2, setColour2] = useState('red')
    const [colour3, setColour3] = useState('red')
    const [colour4, setColour4] = useState('red')
    const [colour5, setColour5] = useState('red')
    const [colour6, setColour6] = useState(false)


    const [MinPasswordLength] = useState('7')
    const [MinUpperCaseInPassword] = useState('1')
    const [MinLowerCaseInPassword] = useState('1')
    const [MinNumericDigitsInPassword] = useState('1')
    const [MinSpecialCharsInPassword] = useState('1')

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'UserPassword') {
            validationPass(e.target.value)
        }
    }

    const Sign_Up_User = async (e) => {
        e.preventDefault()
        if (colour6 && colour1 === 'green' && colour2 === 'green' && colour3 === 'green' && colour4 === 'green' && colour5 === 'green' && value.UserName) {
              fetchPostData('https://apigoldline.com:5002/api/Chat/InsertChat', value)
                .then((res) => {
                  if (res) {
                    if(res[0].Message === 'Insert Successfully'){
                        toastifySuccess(res[0].Message)
                        navigate('/')
                    } else toastifyError(res[0].Message)
                  }
                })
            // const val = EncryptedList(JSON.stringify(value));
            // const data = {
            //     "Data": val
            // }
            // try {
            //     const res = await axios.post('https://https://apigoldline.com:5002/api/Chat/InsertChat', data);
            //     if (res.data.success) {
            //         toastifySuccess(res.data.Message)
            //     } else {
            //         toastifyError(res.data.Message)
            //     }
            // } catch (error) {
            //     console.log(error);
            // }
        } else toastifyError('Please Enter The Valid Details ')

    }

    // Password Validation
    const validationPass = (val) => {
        if (val?.length >= MinPasswordLength) {
            setColour1('green')
        } else {
            setColour1('red')
        }
        if (val.match(`(?=(.*[A-Z]){${MinUpperCaseInPassword}})`)) {
            setColour2('green')
        } else {
            setColour2('red')
        }
        if (val.match(`(?=(.*[a-z]){${MinLowerCaseInPassword},})`)) {
            setColour3('green')
        } else {
            setColour3('red')
        }
        // eslint-disable-next-line
        if (val.match(`(?=(.*[0-9]){${MinNumericDigitsInPassword}})`)) {
            // eslint-disable-next-lin
            setColour4('green')
        } else {
            setColour4('red')
        }
        // eslint-disable-next-line
        if (val.match(`(?=(.*[-\#\$\.\%\&\@\*]){${MinSpecialCharsInPassword}})`)) {
            setColour5('green')
        } else {
            setColour5('red')
        }

        if (val.match(/^\S.*[a-zA-Z\s]*$/)) {
            setColour6(true)
        } else {
            setColour6(false)
        }
        // eslint-disable-next-line
    }

    const style = {
        boxShadow: "2px 2px 3px 3px #ccc",
        border: "2px #eee",
        padding: "20px",
        marginTop: "25px"
    }

    return (
        <>
            <section className="vh-100" style={{ backgroundColor: '#ddd' }}>
                <div className="container-fluid py-4 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-5 col-xl-4">
                            <div className="card shadow-2-strong" style={{ borderRadius: '1rem', }}>
                                <div className="card-body  ">
                                    <h3 className="text-center">Sign Up</h3>
                                    <hr />
                                    <div className="form-group">
                                        {/* <label className="form-label">UserName</label> */}
                                        <input placeholder="UserName" name='UserName' value={value.UserName} className="form-control" type="text" onChange={handleChange} />
                                    </div>

                                    <div className="form-group mt-3">
                                    {/* <label className="form-label">UserName</label> */}
                                        <input placeholder="Password" autoComplete="new-password" name='UserPassword' value={value.UserPassword} className="form-control form-control-lg" type="password" onChange={handleChange} />
                                    </div>

                                    {/* <div className="form-group mt-3"> */}
                                    {/* <label className="form-label">Dispaly</label> */}
                                        {/* <input placeholder="Display" name='DisplayName' value={value.DisplayName} className="form-control form-control-lg" onChange={handleChange} />
                                    </div> */}
                                    <div className='mt-1 login-btn text-center'>
                                        <Link to='/'>Already a member?<span> Log In</span> </Link>
                                    </div>
                                    <div className='mt-1 text-center '>
                                        <button className='btn btn-md btn-login' onClick={Sign_Up_User} type="submit">Sign Up</button>
                                    </div>
                                    <div className="col-md-12">
                                        <div style={style}>
                                            <form>
                                                {/* <p style={{ fontWeight: "bold" }}>All checkmarks must turn green, password must have:</p> */}
                                                <p><i style={{ color: colour1, fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> At least 7 characters</p>
                                                <p><i style={{ color: colour2, fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> At least 1 uppercase letter</p>
                                                <p><i style={{ color: colour3, fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> At least 1 lowercase letter</p>
                                                <p><i style={{ color: colour4, fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> At least 1 number </p>
                                                <p><i style={{ color: colour5, fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> At least 1 special character</p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUp