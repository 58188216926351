import React, { useState } from 'react';
import onlineIcon from '../../icons/onlineIcon.png';
import offIcon from '../../icons/Button_Icon_Red.svg.png';
import user2 from '../../icons/user2.png'
import './TextContainer.css';
import { encrypt, getShowingMonthDateYear } from '../Commun/Utlity';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useScrollTo } from 'react-scroll-to-bottom';
import AlertModal from '../AlertModal/AlertModal';

export const TextContainer = ({moonfox, setAlert, alert, Update_Message, editCountValue, setEditCountValue, setGroupId, ShowMultiGroup, handleShow, globalIndex, ws, filterUserList, setFilterUserList, userList, setShowMess, showMess, name, setUserName, username, setGroupName, groupName }) => {

  const [mess] = useState("{'userName': 'Hello', 'firstName': 'Praveenuuu', 'lastName': 'Praveenuuu'}")

  const filter_User_List = (e) => {
    if (e.target.value === '') {
      setFilterUserList(userList)
    } else {
      const result = userList.filter((item) => {
        return item.UserName.toLowerCase().includes(e.target.value.toLowerCase())
      });
      setFilterUserList(result)
    }
  }

  // const Update_Message = (FromUser, ToUsers) => {
  //   const val = {
  //     'IsRead': true, 'ReadDateTime': getShowingMonthDateYear(new Date()), 'FromUser': FromUser, 'ToUsers': ToUsers,
  //   }
  //   fetchPostData('https://rmsapi2.arustu.com/api/ChatMessage/UpdateMessage', val)
  // }

  const navigate = useNavigate()
  useEffect(() => {
    if (!sessionStorage.getItem('UserName')) {
      navigate('/')
    }
  }, [])


  const logout = (e) => {
    e.preventDefault();
    var reqLogout = {
      Method: "Logout",
      FromUserName: sessionStorage.getItem('UserName')
    };
    var plain = JSON.stringify(reqLogout);
    var secret = encrypt(plain);
    ws.send(secret);
    sessionStorage.removeItem('UserName')
    sessionStorage.removeItem('UserPassword')
    navigate('/')
    // window.location.reload();
  }

  const sendApi = (e) => {
    e.preventDefault();
    var reqSend = {
      Method: "Send",
      FromUserName: sessionStorage.getItem('UserName'),
      ToUserNameList: ['X'],
      Type: '0',
      GroupName: '',
      ContentType: "MoonfoxDataApi",
      Content: JSON.stringify({ "userName": sessionStorage.getItem('UserName'), "firstName": sessionStorage.getItem('UserName'), "lastName": sessionStorage.getItem('UserName') }),
      // Content:mess
    };
    var plain = JSON.stringify(reqSend);
    var secret = encrypt(plain);
    console.log(reqSend);
    moonfox.send(secret);
  }

  const sendMessage = (u) => {
    var reqSend = {
        Method: "Send",
        FromUserName: sessionStorage.getItem('UserName'),
        ToUserNameList: u.split(','),
        Type: '0',
        GroupName: 'Ref',
        Content: JSON.stringify({
            img:'',
            Message: ''
        })
    };
    var plain = JSON.stringify(reqSend);
    var secret = encrypt(plain);
    ws.send(secret);    
}

  return (
    <>
      <div className='textContainer' style={{ paddingTop: "0px" }}>
        <div>
          <div class="chat-sidebar">
            <div class="chat-sidebar-header">
              <div class="d-flex align-items-center">
                <div class="chat-user-online">
                  <img className='sidebaricon' alt='Online Icon' src={user2} />
                </div>
                <div class="flex-grow-1 ms-2">
                  <p class="mb-0 text-white ml-3" >{sessionStorage.getItem('UserName')}</p>
                </div>
                <div className='mr-4'>
                  <span>
                    <Link to=''>
                      <i className={`fa fa-bell ${alert?'text-danger':'text-white'} `}data-toggle="modal" data-target="#myModal"></i>
                    </Link>
                  </span>
                </div>

                {/* <div className="item-action dropdown ml-3 ">
                  <a data-toggle="dropdown" data-toggle1="tooltip" data-placement="right" title="More" aria-expanded="false" style={{ fontSize: '20px', cursor: 'pointer' }}>
                    <i class="fa fa-ellipsis-h text-white"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right " x-placement="bottom-end"
                    style={{ position: 'absolute', top: 0, left: 0, }}>
                    <a className="dropdown-item" href='/' >
                      <i className="dropdown-icon fa fa-sign-out" /> LogOut</a>
                  </div>
                </div> */}

                <div className="dropdown" >
                  <a data-toggle="dropdown" data-toggle1="tooltip" title="LogOut" aria-expanded="false" style={{ fontSize: '20px', cursor: 'pointer' }}>
                    <i class="fa fa-ellipsis-h text-white"></i>
                  </a>
                  <div class="dropdown-menu" id='dropdownMenuBox'  >
                    <Link className="dropdown-item" to='' onClick={() => {
                      // setGroupId('')
                      ShowMultiGroup()
                    }
                    } >
                      <i className="dropdown-icon fa fa-users" /> New Group</Link>
                    <a className="dropdown-item" href='/' onClick={logout} >
                      <i className="dropdown-icon fa fa-sign-out" /> LogOut</a>
                    <Link className="dropdown-item" to='' onClick={sendApi} >
                      <i className="dropdown-icon fa fa-users" /> Api</Link>
                  </div>
                </div>
              </div>
              <div class="mb-3"></div>
              < div class="input-group mb-3 p-0" style={{ marginLeft: '-10px' }}>
                <input id="serachChatUser" placeholder="Search here.." type="text" className="form-control bg-light border-0  form-control" onChange={filter_User_List} />
                <button type="button" id="searchbtn-addon" class="btn bg-light text-white"><i class="fa fa-search align-middle"></i>
                </button>
                <Link to='' className="text-white mt-2 pl-2" onClick={handleShow}>
                  <i class="fa fa-users" aria-hidden="true" ></i>
                </Link>
              </div>
            </div>
          </div>

          {/* {
            OnlineUsername?.map((item, key) => {
              if (item.Online === true) {
                return (
                  <>
                    <div className='activeContainer' key={key}>
                      <h2>
                        <div className='activeItem' style={{ backgroundColor: item.UserName === username ? '#141414' : '', cursor: 'pointer', color: 'white' }} onClick={() => setUserName(item.UserName)}>
                          <div className="leftInnerContainer">
                            <img className='home-icon' alt='Online Icon' src={user2} />
                            <span className='' style={{ fontSize: '15px', padding: '0px 10px' }}></span>
                            <img className="onlineIcon" src={onlineIcon} alt="online icon" style={{ height: '15px', width: '15px' }} />
                          </div>
                          <span style={{
                            fontSize: '20px',
                            color: showMess ?
                              username === showMess[0].FromUserName ?
                                setShowMess() : showMess[0].FromUserName === item.UserName ?
                                  'red'
                                  : ''
                              : ''
                          }}> {item.UserName}</span>
                        </div>
                      </h2>
                    </div>
                  </>
                )
              }
            }
            )
          } */}

          <div style={{ overflowY: 'scroll', maxHeight: '80vh',overflowX:'hidden' }}>
            {
              filterUserList?.map((item, key) => {

                if (item.UserName.toLowerCase() !== sessionStorage.getItem('UserName').toLowerCase()) {

                  // if (item.DisplayName) {
                  //  const result =  item.DisplayName.split(',')?.map(val => {
                  //     if (val === sessionStorage.getItem('UserName')) {
                  //       return (
                  //         <>
                  //           <div className='activeContainer' key={key}>
                  //             <h2>
                  //               <div className='activeItem' style={{
                  //                 backgroundColor: groupName ?
                  //                   item.UserName === groupName ?
                  //                     ' rgb(229, 223, 223)'
                  //                     : ''
                  //                   : item.UserName === username ?
                  //                     ' rgb(229, 223, 223)'
                  //                     : '',
                  //                 cursor: 'pointer',
                  //               }} onClick={() => {
                  //                 setGroupId(item.ID); 
                  //                 setEditCountValue(editCountValue + 1)
                  //                 if (item.DisplayName) {
                  //                   setUserName(item.DisplayName)
                  //                   setGroupName(item.UserName)
                  //                 } else {
                  //                   setUserName(item.UserName)
                  //                   setGroupName('')
                  //                 }
                  //               }}>
                  //                 <div className="leftInnerContainer">
                  //                   <img className='home-icon' alt='Online Icon' src={user2} />
                  //                   <span className='' style={{ fontSize: '15px', padding: '0px 10px' }}></span>
                  //                   <img className="onlineIcon" src={item.DisplayName ? '' : item.IsOnline === true ? onlineIcon : offIcon} alt="online icon" style={{ height: '15px', width: '15px' }} />
                  //                 </div>
                  //                 <span
                  //                   // onClick={
                  //                   //   () => {
                  //                   //     showMess ? showMess[0].FromUserName === item.UserName ?
                  //                   //       Update_Message(showMess[0].FromUserName, name)
                  //                   //       :
                  //                   //       <></>
                  //                   //       :
                  //                   //       <></>
                  //                   //   }
                  //                   // }
                  //                   style={{
                  //                     fontSize: '20px',
                  //                     color: showMess ?
                  //                       showMess.GroupName ?
                  //                         showMess.GroupName === item.DisplayName ? 'red'
                  //                           : ''
                  //                         :
                  //                         username === showMess.Messages[0].FromUserName ?
                  //                           setShowMess()
                  //                           : showMess.Messages[0].FromUserName === item.UserName ?
                  //                             'red'
                  //                             : ''
                  //                       : ''

                  //                     // username === showMess[0].FromUserName ?
                  //                     //   setShowMess()
                  //                     //   : showMess[0].FromUserName === item.UserName ?
                  //                     //     'red'
                  //                     //     : ''
                  //                     // : ''
                  //                   }}
                  //                 >
                  //                   {
                  //                     item.UserName
                  //                   }

                  //                   {/* {
                  //                    item.DisplayName ?
                  //                     item.DisplayName.split(',')?.map(val => {
                  //                     if(val === sessionStorage.getItem('UserName')){
                  //                      return item.UserName
                  //                     }
                  //                    })
                  //                    : item.UserName
                  //                  } */}
                  //                 </span>
                  //               </div>
                  //             </h2>
                  //           </div>
                  //         </>
                  //       )
                  //     } 
                  //   })
                  //   return result
                  // } else {
                  //   return (
                  //     <>
                  //       <div className='activeContainer' key={key}>
                  //         <h2>
                  //           <div className='activeItem' style={{
                  //             backgroundColor: groupName ?
                  //               item.UserName === groupName ?
                  //                 ' rgb(229, 223, 223)'
                  //                 : ''
                  //               : item.UserName === username ?
                  //                 ' rgb(229, 223, 223)'
                  //                 : '',
                  //             cursor: 'pointer',
                  //           }} onClick={() => {
                  //             setGroupId('');
                  //             if (item.DisplayName) {
                  //               setUserName(item.DisplayName)
                  //               setGroupName(item.UserName)
                  //             } else {
                  //               setUserName(item.UserName)
                  //               setGroupName('')
                  //             }
                  //           }}>
                  //             <div className="leftInnerContainer">
                  //               <img className='home-icon' alt='Online Icon' src={user2} />
                  //               <span className='' style={{ fontSize: '15px', padding: '0px 10px' }}></span>
                  //               <img className="onlineIcon" src={item.DisplayName ? '' : item.IsOnline === true ? onlineIcon : offIcon} alt="online icon" style={{ height: '15px', width: '15px' }} />
                  //             </div>
                  //             <span
                  //               // onClick={
                  //               //   () => {
                  //               //     showMess ? showMess[0].FromUserName === item.UserName ?
                  //               //       Update_Message(showMess[0].FromUserName, name)
                  //               //       :
                  //               //       <></>
                  //               //       :
                  //               //       <></>
                  //               //   }
                  //               // }
                  //               style={{
                  //                 fontSize: '20px',
                  //                 color: showMess ?
                  //                   showMess.GroupName ?
                  //                     showMess.GroupName === item.DisplayName ? 'red'
                  //                       : ''
                  //                     :
                  //                     username === showMess.Messages[0].FromUserName ?
                  //                       setShowMess()
                  //                       : showMess.Messages[0].FromUserName === item.UserName ?
                  //                         'red'
                  //                         : ''
                  //                   : ''

                  //                 // username === showMess[0].FromUserName ?
                  //                 //   setShowMess()
                  //                 //   : showMess[0].FromUserName === item.UserName ?
                  //                 //     'red'
                  //                 //     : ''
                  //                 // : ''
                  //               }}
                  //             >
                  //               {
                  //                 item.UserName
                  //               }

                  //               {/* {
                  //                item.DisplayName ?
                  //                 item.DisplayName.split(',')?.map(val => {
                  //                 if(val === sessionStorage.getItem('UserName')){
                  //                  return item.UserName
                  //                 }
                  //                })
                  //                : item.UserName
                  //              } */}
                  //             </span>
                  //           </div>
                  //         </h2>
                  //       </div>
                  //     </>
                  //   )
                  // }

                  return (
                    <>
                      <div className='activeContainer' key={key}>
                        <h2>
                          <div className='activeItem' style={{
                            backgroundColor: groupName ?
                              item.UserName === groupName ?
                                ' rgb(229, 223, 223)'
                                : ''
                              : item.UserName === username ?
                                ' rgb(229, 223, 223)'
                                : '',
                            cursor: 'pointer',
                          }} onClick={(e) => {
                            if (showMess) {
                              console.log(showMess);
                              if (username === showMess.Messages[0].FromUserName) {
                                setShowMess();
                              } else if (showMess.GroupName === item.UserName) {
                                Update_Message(item.UserName, sessionStorage.getItem('UserName'), true, getShowingMonthDateYear(new Date()), showMess.GroupName )
                              } else  if (showMess.Messages[0].FromUserName === item.UserName )  {
                                Update_Message(item.UserName, sessionStorage.getItem('UserName'), true, getShowingMonthDateYear(new Date()), null ); sendMessage(item.UserName)
                              }
                            }

                            if (item.DisplayName) {
                              sessionStorage.setItem('name', item.DisplayName)
                              setUserName(item.DisplayName)
                              setGroupName(item.UserName)
                              sessionStorage.setItem('group', item.UserName)
                            } else {
                              
                              sessionStorage.setItem('name', item.UserName)
                              setUserName(item.UserName)
                              setGroupName('')
                              sessionStorage.setItem('group', item.UserName)
                            }
                          }}>
                            <div className="leftInnerContainer">
                              <img className='home-icon' alt='Online Icon' src={user2} />
                              <span className='' style={{ fontSize: '15px', padding: '0px 10px' }}></span>
                              <img className="onlineIcon" src={item.DisplayName ? '' : item.IsOnline === true ? onlineIcon : offIcon} alt="online icon" style={{ height: '15px', width: '15px' }} />
                            </div>
                            <span
                              // onClick={
                              //   () => {
                              //     showMess ? showMess[0].FromUserName === item.UserName ?
                              //       Update_Message(showMess[0].FromUserName, name)
                              //       :
                              //       <></>
                              //       :
                              //       <></>
                              //   }
                              // }
                              style={{
                                fontSize: '20px',
                                color: showMess ?
                                  showMess.GroupName ?
                                    showMess.GroupName === item.DisplayName ? 'red'
                                      : ''
                                    :
                                    username === showMess.Messages[0].FromUserName ?
                                      setShowMess()
                                      : showMess.Messages[0].FromUserName === item.UserName ?
                                        'red'
                                        : ''
                                  : ''

                                // username === showMess[0].FromUserName ?
                                //   setShowMess()
                                //   : showMess[0].FromUserName === item.UserName ?
                                //     'red'
                                //     : ''
                                // : ''
                              }}
                            >
                              {
                                item.UserName
                              }

                              {/* {
                                item.DisplayName ?
                                 item.DisplayName.split(',')?.map(val => {
                                 if(val === sessionStorage.getItem('UserName')){
                                  return item.UserName
                                 }
                                })
                                : item.UserName
                              } */}
                            </span>
                          </div>
                        </h2>
                      </div>
                    </>
                  )
                }
              }
              )
            }
          </div>
        </div>
      </div>
      <AlertModal {...{setAlert, alert}} />
    </>
  );
}

