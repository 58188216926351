import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import makeAnimated from "react-select/animated";
import SelectBox from '../Commun/SelectBox';
import { components } from "react-select";
import { toastifyError, toastifySuccess } from '../Commun/AlertMsg';
import { fetchPostData } from '../hooks/Api';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <p className='ml-2 d-inline'>{props.label}</p>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated()

const MultiGroup = ({get_User_List, editCountValue, setGroupId, groupId, ws, userList, CloseMultiGroup, showMulti }) => {

    const [value, setValue] = useState({
        'UserName': '',
        'UserPassword': '',
        'IsOnline': '',
        'DisplayName': ''
    })

    const [editData, setEditData] = useState([])

    useEffect(() => {
        if (groupId) {
            const val = {
                ID: groupId
            }
            fetchPostData('https://apigoldline.com:5002/api/Chat/GetSingleDataChat', val)
                .then((res) => {
                    console.log(res);
                    if (res) {
                        setEditData(res)
                    }
                })
        } else {
            setValue({
                ...value,
                'UserName': '',
                'UserPassword': '',
                'IsOnline': '',
                'DisplayName': ''
            }); 
            setSelected({
                optionSelected: null
            })
        }
    }, [editCountValue])

    useEffect(() => {
        if (editData.length > 0) {
            setValue({
                ...value,
                'UserName': editData[0].UserName,
                'UserPassword': editData[0].UserPassword,
                'IsOnline': editData[0].IsOnline,
                'DisplayName': editData[0].DisplayName
            });
            setSelected({
                optionSelected: editData[0]?.GroupName ? changeArrayFormat_WithFilter(editData[0]?.GroupName
                ) : '',
            });
        }  else {
            setValue({
                ...value,
                'UserName': '',
                'UserPassword': '',
                'IsOnline': '',
                'DisplayName': ''
            }); 
            setSelected({
                optionSelected: null
            })
        }
    }, [editData])


    const [Selected, setSelected] = useState({
        optionSelected: null
    })

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    }

    const handlChanges = (selected) => {
        setSelected({
            optionSelected: selected
        });
        var id = []
        var name = []
        if (selected) {
            selected.map((item, i) => {
                id.push(item.value);
                name.push(item.label)
            })
            setValue({
                ...value,
                ['DisplayName']: name.toString()
            })
        }
    }

    const Create_Group = async (e) => {
        e.preventDefault()
        const res = value.DisplayName + ',' + `${sessionStorage.getItem('UserName')}`
        const val = {
            'UserName': value.UserName,
            'UserPassword': '',
            'IsOnline': '',
            'DisplayName': res
        }
        if (value.UserName && value.DisplayName) {
            console.log(value);
            fetchPostData('https://apigoldline.com:5002/api/Chat/InsertChat', val)
                .then((res) => {
                    if (res) {
                        if (res[0].Message === 'Insert Successfully') {
                            get_User_List()
                            toastifySuccess(res[0].Message); CloseMultiGroup(); reset_Hooks()
                        } else toastifyError(res[0].Message)
                    }
                })
        } else toastifyError('Please Enter The Valid Details ')

    }

    const Update_Group = async (e) => {
        e.preventDefault()
        const res = value.DisplayName + ',' + `${sessionStorage.getItem('UserName')}`
        const val = {
            'UserName': value.UserName,
            'UserPassword': '',
            'IsOnline': '',
            'DisplayName': res,
            // 'DisplayName': value.DisplayName,
            'ID':groupId
        }
        if (value.UserName && value.DisplayName) {
            fetchPostData('https://apigoldline.com:5002/api/Chat/UpdateChat', val)
                .then((res) => {
                    if (res) {
                        if (res[0].Message === 'Update Successfully') {
                            get_User_List()
                            toastifySuccess(res[0].Message); CloseMultiGroup(); reset_Hooks()
                        } else toastifyError(res[0].Message)
                    }
                })
        } else toastifyError('Please Enter The Valid Details ')

    }

    const reset_Hooks = () => {
        setValue({
            ...value,
            'UserName': '',
            'UserPassword': '',
            'IsOnline': '',
            'DisplayName': ''
        }); 
        setSelected({
            optionSelected: null
        })
    }


    return (
        <Modal show={showMulti} onHide={CloseMultiGroup} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header >
                <Modal.Title >{groupId ? 'Edit Group' : 'New Group'}</Modal.Title>
                <span><i className='fa fa-close' onClick={CloseMultiGroup} ></i></span>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <div className="dropdown__box  ">
                            <Form.Control
                                type="text"
                                name='UserName'
                                value={value.UserName}
                                onChange={handleChange}
                                placeholder="Enter Group Name"
                            />
                            <Form.Label >Name</Form.Label>
                        </div>
                    </Form.Group>
                    <div className="mb-4 mt-4">
                        <div className="dropdown__box ">
                            <SelectBox
                                options={changeArrayFormat(userList)}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option, MultiValue, animatedComponents }}
                                onChange={handlChanges}
                                allowSelectAll={true}
                                value={Selected.optionSelected}
                            />
                            <Form.Label >Enter Group Member</Form.Label>
                        </div>
                    </div>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={
                    () => {
                        reset_Hooks()
                        CloseMultiGroup()
                    }
                    }>
                    Close
                </Button>
                {
                    groupId ?
                        <Button variant="secondary" onClick={Update_Group} >
                            Edit Group
                        </Button>
                        :
                        <Button variant="secondary" onClick={Create_Group} >
                            Create Group
                        </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default MultiGroup

export const changeArrayFormat = (data) => {
    const result = data?.map((sponsor) => {
        if (sessionStorage.getItem('UserName') !== sponsor.UserName && sponsor.DisplayName === null) {
            return { value: sponsor.UserName, label: sponsor.UserName }
        }
    })
    const result1 = result.filter(function (element) {
        return element !== undefined;
    });
    return result1
}

export const changeArrayFormat_WithFilter = (data) => {


    const result = data?.map((sponsor) => {
        if (sessionStorage.getItem('UserName') !== sponsor.value) {
            return { value: sponsor.value, label: sponsor.value }
        }
    })
    const result1 = result.filter(function (element) {
        return element !== undefined;
    });
    return result1
    console.log(data);
    // const result = data?.map((sponsor) =>
    //     ({ value: sponsor.value, label: sponsor.value })
    // )
    // console.log(result);
    // return result
}