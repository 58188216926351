import React, { useState } from 'react'
import { DecryptedList, encrypt, EncryptedList } from '../Commun/Utlity';
import './Join.css'
import { Link, useNavigate } from 'react-router-dom';
import { toastifyError, toastifySuccess } from '../Commun/AlertMsg';
import axios from 'axios';

const Join = ({ initWebSocket, globalIndex, ws, setName }) => {
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false);
  // const [username, setUsername] = useState()
  const [value, setValue] = useState({
    'UserName': '',
    'UserPassword': '',
  })
  // show password
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });
    if (e.target.name === 'UserName') setName(e.target.value)
  }

  const Login_User = async (e) => {
    e.preventDefault()
    const { UserName, UserPassword } = value

    if (UserName && UserPassword) {
      const val = EncryptedList(JSON.stringify(value));
      const data = {
        "Data": val
      }
      try {
        const res = await axios.post('https://apigoldline.com:5002/api/Chat/LoginChatUser', data);

        if (res.data.success) {
          toastifySuccess('Login Successfully');
          const decr = DecryptedList(res.data.data)
          const TextData = JSON.parse(decr)
          console.log(TextData.Table[0].UserName);
          sessionStorage.setItem('UserName', TextData.Table[0].UserName)
          sessionStorage.setItem('UserPassword', TextData.Table[0].UserPassword)
          login(TextData.Table[0].UserName)
        } else {
          toastifyError(res.data.Message)
        }
      } catch (error) {
        console.log(error);
      }
    } else toastifyError('Please Enter The Valid Details')
  }

  const login = (UserName) => {
    var reqLogin = {
      Method: "Login",
      FromUserName: UserName,
      GlobalIndex: globalIndex
    };
    var plain = JSON.stringify(reqLogin);
    var secret = encrypt(plain);
    ws.send(secret);
    navigate('/chat')
  };

  return (
    <>

      {/* <div className="joinOuterContainer">
        <div className="joinInnerContainer">
          <h1 className="heading">Join</h1>
          <div>
            <input placeholder="Name" className="joinInput" type="text" onChange={(e) => { setUsername(e.target.value); setName(e.target.value) }} />
          </div>
          <button className={'button mt-20'} onClick={login} type="submit">Sign In</button>
        </div>
      </div> */}

      <section className="vh-100" style={{ backgroundColor: '#ddd' }}>
        <div className="container-fluid py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-5 col-xl-4">
              <div className="card shadow-2-strong" style={{ borderRadius: '1rem', }}>
                <div className="card-body p-5 ">
                  <h3 className="text-center" >Sign In</h3>
                  <hr />
                  <div className="form-group">
                    <label className="form-label">Username</label>
                    <input placeholder="UserName" name='UserName' value={value.UserName} className="form-control " type="text" onChange={handleChange} />
                  </div>

                  <div className="form-group mt-3" style={{ position: 'relative' }}>
                    <label className="form-label">Password</label>
                    <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} onClick={togglePassword} style={{ position: 'absolute', top: '60%', right: '3%' }}></i>
                    <input type={passwordShown ? "text" : "password"} placeholder="Password" autoComplete="new-password" name='UserPassword' value={value.UserPassword} className="form-control " onChange={handleChange} />
                  </div>
                  <div className='forgot' >
                    <Link to="/forgot-password" className="float-right small " onClick={() => {
                    }}>Forgot Password?</Link>
                  </div>
                  <div className='mt-4 text-center'>
                    <button className='btn btn-lg btn-login' onClick={Login_User} type="submit">Login</button>
                  </div>
                  <div className='mt-2 login-btn text-center'>
                    <Link to='/signup'>Don't have an account?<span> Sign Up </span> </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Join