import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Group from "../Group/Group";
import MultiGroup from "../Group/MultiGroup";
import { fetchPostData } from "../hooks/Api";
import { InfoBar } from '../InfoBar/InfoBar';
import { Input } from '../Input/Input';
import { Messages } from '../Messages/Messages';
import { TextContainer } from '../TextContainer/TextContainer'
import './Chat.css';

const Chat = ({moonfox, messageStatus, setAlert, alert, Update_Message, setOnlineStatus, onlineStatus, user_Message_Insert, get_User_List, user_Status, filterUserList, setFilterUserList, userList, setShowMess, showMess, messages, name, initWebSocket, globalIndex, ws, OnlineUsername, setMessagesData, MessData, MessagesData, username, setUserName, groupName, setGroupName }) => {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [groupId, setGroupId] = useState('')
  const [showMulti, setShowMulti] = useState(false);
  const CloseMultiGroup = () => setShowMulti(false);
  const ShowMultiGroup = () => setShowMulti(true);

  const [editCountValue, setEditCountValue] = useState(0)

  // useEffect(() => {
  //   user_Old_Message()
  // }, [username])

  const user_Old_Message = () => {
    const val = {
      'FromUser': sessionStorage.getItem('UserName'), 'ToUsers': username, 'FromUser1': username, 'ToUsers1': sessionStorage.getItem('UserName'),
    }
    fetchPostData('https://apigoldline.com:5002/api/ChatMessage/GetData_ChatUsersRms', val)
      .then(res => {
        if (res) setMessagesData(res)
        else setMessagesData([])
      })
  }

  return (
    <div className="outerContainer">
      <TextContainer {...{moonfox, setAlert, alert, Update_Message, editCountValue, setEditCountValue, setGroupId, setMessagesData, user_Status, globalIndex, ws, filterUserList, setFilterUserList, userList, setShowMess, showMess, messages, name, OnlineUsername, setUserName, username, MessagesData, handleShow, setGroupName, ShowMultiGroup, groupName }} />
      <div className="container">
        {
          username ?
            <>
              <InfoBar {...{ editCountValue, groupId, setGroupId, ShowMultiGroup, groupName, userList, user_Old_Message, name, username, ws }} />
              <Messages {...{messageStatus, messages, MessagesData, name, username }} />
              <Input {...{ setOnlineStatus, onlineStatus, user_Message_Insert, groupName, initWebSocket, globalIndex, ws, name, setMessagesData, MessData, username }} />
              <Group {...{ ws, userList, handleClose, handleShow, show, setShow, user_Message_Insert }} />
              <MultiGroup {...{ get_User_List, editCountValue, groupId, ws, userList, CloseMultiGroup, showMulti }} />
              {
                onlineStatus === 'offline' ?
                  <div className="netStatus">
                    <h5>Internet Disconnected</h5>
                  </div>
                  : <></>
              }
            </>
            :
            <>
              <Dashboard />
              <Group {...{ ws, userList, handleClose, handleShow, show, setShow, user_Message_Insert }} />
              {/* <InfoBar {...{editCountValue, groupId, setGroupId, ShowMultiGroup, groupName, userList, user_Old_Message, name, username, ws }} /> */}
              <MultiGroup {...{ get_User_List, editCountValue, groupId, ws, userList, CloseMultiGroup, showMulti }} />
              {
                onlineStatus === 'offline' ?
                  <div className="netStatus">
                    <h5>Internet Disconnected</h5>
                  </div>
                  : <></>
              }
            </>
        }
      </div>
    </div>
  )
}
export default Chat