import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom'

import {Message} from './Message/Message'

import './Messages.css';

export const Messages = ({messageStatus, oldMess, messages, sendMessages, MessagesData, name, username }) => (
    <ScrollToBottom className="messages">
       <div key={'1'}><Message {...{messageStatus, oldMess, messages, sendMessages, MessagesData, name, username}} /></div>
    </ScrollToBottom>
);