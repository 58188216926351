import React, { useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import makeAnimated from "react-select/animated";
import SelectBox from '../Commun/SelectBox';
import { components } from "react-select";
import { toastifyError, toastifySuccess } from '../Commun/AlertMsg';
import { fetchPostData } from '../hooks/Api';
import { encrypt } from '../Commun/Utlity';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <p className='ml-2 d-inline'>{props.label}</p>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated()

const Group = ({ ws, userList, handleClose, show, user_Message_Insert, setMessagesData, MessData, }) => {


    const [value, setValue] = useState({
        'UserName': '',
        'UserPassword': '',
        'IsOnline': '',
        'DisplayName': ''
    })

    const [textMessage, setTextMessage] = useState('')

    const [Selected, setSelected] = useState({
        optionSelected: null
    })

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    }

    const handlChanges = (selected) => {
        setSelected({
            optionSelected: selected
        });
        var id = []
        var name = []
        if (selected) {
            selected.map((item, i) => {
                id.push(item.value);
                name.push(item.label)
            })
            setValue({
                ...value,
                ['DisplayName']: name.toString()
            })
        }
    }

    const sendMessage = (e) => {
        e.preventDefault();
        if (!value.DisplayName) {
            toastifyError('Empty ToUserName');
            return;
        }
        if (!textMessage) {
            toastifyError('Empty Message');
            return;
        } else if (textMessage.trim() === '') {
            toastifyError('Empty Message');
            return;
        }
        var reqSend = {
            Method: "Send",
            FromUserName: sessionStorage.getItem('UserName'),
            ToUserNameList: value.DisplayName.split(','),
            Type: '0',
            GroupName: '',
            Content: JSON.stringify({
                img:'',
                Message: textMessage
            })
        };
        var plain = JSON.stringify(reqSend);
        var secret = encrypt(plain);
        ws.send(secret);

        if (textMessage) {
            user_Message_Insert(sessionStorage.getItem('UserName'),  value.DisplayName, textMessage, new Date(), '')
        }

        setTimeout(() => {
            setTextMessage(''); reset(); handleClose() 
        }, [100])
        
    }

    const reset = () => {
        setSelected({
            optionSelected: null
        })
    }

    // const Create_Group = async (e) => {
    //     e.preventDefault()
    //     if (value.UserName && value.DisplayName) {
    //         console.log(value);
    //           fetchPostData('https://rmsapi2.arustu.com/api/Chat/InsertChat', value)
    //             .then((res) => {
    //               if (res) {
    //                 if(res[0].Message === 'Insert Successfully'){
    //                     toastifySuccess(res[0].Message); handleClose(); reset_Hooks()
    //                 } else toastifyError(res[0].Message)
    //               }
    //             })
    //     } else toastifyError('Please Enter The Valid Details ')

    // }

    // const reset_Hooks = () => {
    //     setValue({
    //         ...value,
    //         'UserName': '',
    //         'UserPassword': '',
    //         'IsOnline': '',
    //         'DisplayName': ''
    //     })
    // }


    return (
        <>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header >
                    <Modal.Title >Multiple Group Chat</Modal.Title>
                    <span><i className='fa fa-close' onClick={()=> {reset(); handleClose()}} ></i></span>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                            <div className="dropdown__box  ">
                                <Form.Control
                                    type="text"
                                    name='UserName'
                                    value={sessionStorage.getItem('UserName')}
                                    onChange={handleChange}
                                    placeholder="Enter Group Name"
                                />
                                <Form.Label >Name</Form.Label>
                            </div>
                        </Form.Group>
                        <div className="mb-4 mt-4">
                            <div className="dropdown__box ">
                                <SelectBox
                                    options={changeArrayFormat(userList)}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    onChange={handlChanges}
                                    allowSelectAll={true}
                                    value={Selected.optionSelected}
                                />
                                <Form.Label >Enter Group Member</Form.Label>
                            </div>
                        </div>
                        <div className="dropdown__box  ">
                            <Form.Control
                                type='text'
                                as="textarea" rows={3}
                                name='textMessage'
                                value={textMessage}
                                onChange={(e) => setTextMessage(e.target.value)}
                                placeholder="Enter Message"
                            />
                            <Form.Label >Message</Form.Label>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> {reset(); handleClose()}}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={sendMessage} >
                        Send Group Message
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Group


export const changeArrayFormat = (data) => {
    const result = data?.map((sponsor) => {
        if (sessionStorage.getItem('UserName') !== sponsor.UserName && !sponsor.DisplayName) {
            return { value: sponsor.UserName, label: sponsor.UserName }
        }
    })
    const result1 = result.filter(function (element) {
        return element !== undefined;
    });
    return result1
}