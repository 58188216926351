import React from 'react'
import chat1 from '../../icons/chat1.png'

const Dashboard = () => {
    return (
        <>
            <div class="chat-welcome-section" style={{ margin: 'auto' }}>
                <div class="">
                    <div class="col-md-12 ">
                        <div class=" text-center">
                            <div class="avatar-xl  ">
                                <div class="avatar-title bg-soft-primary rounded-circle">
                                    <img className="chat" src={chat1} />
                                </div>
                            </div>
                            <h4 className='text-bold'>Welcome to  Chat App</h4>
                            <p class="text-muted text-bold ">Select The User From Left Side
                            </p>
                            {/* <span className=''>
                                <i className='fa fa-arrow-left'></i>
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard