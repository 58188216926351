import React, { useEffect, useState } from 'react';
import './Message.css';
import image from '../../../icons/send.png'
import image1 from '../../../icons/delivered.png'
import { fetchPostData } from '../../hooks/Api';

export const Message = ({ MessagesData, name, username, messageStatus }) => {
    
    // const [messageStatus, setMessageStatus] = useState([])

    // console.log(username);
    // useEffect(() => {
    //     user_Old_Message()
    // }, [])

    // const user_Old_Message = () => {
    //     const val = {
    //         'FromUser': sessionStorage.getItem('UserName'), 'ToUsers': username, 'FromUser1': username, 'ToUsers1': sessionStorage.getItem('UserName'),
    //     }
    //     fetchPostData('https://apigoldline.com:5002/api/ChatMessage/GetData_ChatUsersMessage', val)
    //         .then(res => {
    //             if(res) setMessageStatus(res)
    //             else setMessageStatus()
    //             console.log('MessagesData', res);
    //         })
    // }

    return (
        <>
            {
                MessagesData?.map((val, key) => {
                    if (val.GroupName && val.GroupName === sessionStorage.getItem('group')) {
                        const result = username.split(',')?.map(item => {
                            if (sessionStorage.getItem('UserName') === item) {
                                if (sessionStorage.getItem('UserName') === val.FromUser) {
                                    return (
                                        <>
                                            <div key={key} className="messageContainer justifyEnd">
                                                <p className="sentText pr-10"></p>
                                                <div className="messageBox backgroundBlue">
                                                    <p className="messageText colorWhite ">{val.ChatMessage}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img style={{
                                                    width: '200px',
                                                    padding: '20px'
                                                }} src={val.Image} />
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div key={key} className="messageContainer justifyStart">
                                                <div className="messageBox backgroundLight">
                                                    <p className="messageText colorDark">{val.ChatMessage}</p>
                                                </div>
                                                <p className="sentText pl-10">{val.FromUser}</p>
                                                <img src={image} alt='icon'/>
                                            </div>
                                            <img style={{
                                                width: '200px',
                                                padding: '20px'
                                            }} src={val.Image} />
                                        </>
                                    )
                                }
                            }
                        })

                        return result

                    } else {
                        if (username === val.FromUser || username === val.ToUsers) {
                            if (sessionStorage.getItem('UserName') === val.FromUser && !val.GroupName) {
                                return (
                                    <>
                                        {
                                            val.ChatMessage ?
                                                <div key={key} className="messageContainer justifyEnd">
                                                    {/* <p className="sentText pr-10"></p> */}
                                                    <div className="messageBox backgroundBlue">
                                                        <p className="messageText colorWhite">{val.ChatMessage}</p>
                                                    </div>
                                                    {/* <p className="sentText pl-10">{val.ToUsers}</p> */}
                                                    {
                                                        messageStatus?.map((status, key) => {
                                                            if (status.FromUser === sessionStorage.getItem('UserName') && status.ToUsers === sessionStorage.getItem('name')) {
                                                                if (status.ChatMessage === val.ChatMessage) {
                                                                    // console.log(status.ChatMessage);
                                                                    // console.log(val.ChatMessage);
                                                                    if (status.IsRead) {
                                                                        return <img src={image1} style={{ width: '20px', height: '10px', marginTop: '30px' }} alt='icon' />
                                                                    } else {
                                                                        return <img src={image} style={{ width: '20px', height: '10px', marginTop: '30px' }} alt='icon' />
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                    {/* <img src={image} style={{width:'20px', height:'10px', marginTop:'30px'}} alt='icon'/> */}
                                                </div> : <></>
                                        }
                                        <div className="messageContainer justifyEnd">
                                            <img style={{
                                                width: '200px',
                                                padding: '20px'
                                            }} src={val.Image} />
                                        </div>
                                    </>
                                )
                            } else if(!val.GroupName) {
                                return (
                                    <>
                                        {
                                            val.ChatMessage ?
                                                <div key={key} className="messageContainer justifyStart">
                                                    <div className="messageBox backgroundLight">
                                                        <p className="messageText colorDark">{val.ChatMessage}</p>
                                                    </div>
                                                    {/* <p className="sentText pl-10">{val.ToUsers}</p> */}
                                                </div> : <></>
                                        }
                                        <div>
                                            <img style={{
                                                width: '200px',
                                                padding: '20px'
                                            }} src={val.Image} />
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                    // if(username ===  val.FromUser || username ===  val.ToUsers){
                    //     if (sessionStorage.getItem('UserName') === val.FromUser) {
                    //         return (
                    //             <div key={key} className="messageContainer justifyEnd">
                    //                 <p className="sentText pr-10"></p>
                    //                 <div className="messageBox backgroundBlue">
                    //                     <p className="messageText colorWhite">{val.ChatMessage}</p>
                    //                 </div>
                    //             </div>
                    //         )
                    //     } else {
                    //         return (
                    //             <div key={key} className="messageContainer justifyStart">
                    //                 <div className="messageBox backgroundLight">
                    //                     <p className="messageText colorDark">{val.ChatMessage}</p>
                    //                 </div>
                    //                 {/* <p className="sentText pl-10">{val.ToUsers}</p> */}
                    //             </div>
                    //         )
                    //     }
                    // }
                })
            }
        </>
    )


};


// {
//     MessagesData?.map((val, key) => {
//         if (val.type === "receive" && username === val.FromUserName) {
//             return (
//                 <div key={key} className="messageContainer justifyStart">
//                     <div className="messageBox backgroundLight">
//                         <p className="messageText colorDark">{val.Mess}</p>
//                     </div>
//                     <p className="sentText pl-10">{val.FromUserName}</p>
//                 </div>
//             )
//         } else if (val.type === "send" && name === val.FromUserName && username === val.ToUserName) {
//             return (
//                 <div key={key} className="messageContainer justifyEnd">
//                     <p className="sentText pr-10"></p>
//                     <div className="messageBox backgroundBlue">
//                         <p className="messageText colorWhite">{val.Mess}</p>
//                     </div>
//                 </div>
//             )
//         }

//     })
// }