import axios from "axios";
import { DecryptedList, EncryptedList } from "../Commun/Utlity";



// ------get API Request
export const fetchData = async (url) => {
    try {
        const res = await axios.get(url);
        const decr = DecryptedList(res.data.data)
        const TextData = JSON.parse(decr)
        return TextData.Table
    } catch (err) {
        console.log(err);
    }
};

// -------get DATA API With Post Request
export const fetchPostData = async (url, postData) => {
    const values = EncryptedList(JSON.stringify(postData));
    const data = {
        "Data": values
    }
    // console.log(data)
    try {
        const res = await axios.post(url, data);
        const decr = DecryptedList(res.data.data)
        const TextData = JSON.parse(decr)
        return TextData.Table
    } catch (error) {
        console.log(error);  
    }
};

