import React from 'react';
import onlineIcon from '../../icons/onlineIcon.png';
import history1 from '../../icons/history1.png';
import user2 from '../../icons/user2.png'
import './InfoBar.css';
import offIcon from '../../icons/Button_Icon_Red.svg.png';

export const InfoBar = ({groupId, ShowMultiGroup, groupName, userList, user_Old_Message, username }) => {
  return (
    <div className="infoBar">
      <div className="leftInnerContainer">
        <img className='home-icon' alt='Online Icon' src={user2} />
        <span className='' style={{ fontSize: '15px', padding: '0px 6px' }}></span>

        {
          groupName ?
            <>
              <div className="user-box d-block" style={{cursor:'pointer'}} onClick={ShowMultiGroup}>
                <h3 className='m-0 p-0' style={{ fontSize: '20px', fontWeight: '500' }}>{groupName}</h3>
                <span style={{fontSize: '12px'}}>{username}</span>
              </div>
            </>
            :
            userList?.map((val) => {
              if (username === val.UserName) {
                return (
                  <>
                    <img className="onlineIcon" src={val.IsOnline === true ? onlineIcon : offIcon} alt="online icon" />
                    <h3 className='mt-2' style={{ fontSize: '20px', fontWeight: '500' }}>{username}</h3>
                  </>
                )
              }

            }
            )
        }


      </div>
      <div className="rightInnerContainer">
        <img src={history1} style={{ width: '30px', cursor: 'pointer' }} alt="close icon" onClick={user_Old_Message} />
      </div>

    </div>

  );
}